@import "~bootstrap/scss/bootstrap";
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~bootstrap-select/dist/css/bootstrap-select.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import './vars.scss';

html {
    background-color: lightgray;
    --vp-color-primary-rgb: 0,26,91;
    --vp-color-secondary-rgb: 246,190,0;
    --vp-color-secondary-alt-rgb: 251,152,37;
    --vp-color-ternary-alt-rgb: 0, 5, 19;
    --vp-color-ternary-rgb: 102,107,133;
    --vp-color-ternary-light-rgb: 208,213,221;

    --vp-installation-type-color-unknown: rgb(166, 171, 180);
    --vp-installation-type-color-pose: #B42318;
    --vp-installation-type-color-depose: #B2C80A;
    --vp-installation-type-color-offline: #FB9825;

    --vp-color-primary: rgb(var(--vp-color-primary-rgb));
    --vp-color-secondary: rgb(var(--vp-color-secondary-rgb));
    --vp-color-secondary-alt: rgb(var(--vp-color-secondary-alt-rgb));
    --vp-color-ternary-alt: rgb(var(--vp-color-ternary-alt-rgb));
    --vp-color-ternary: rgb(var(--vp-color-ternary-rgb));
    --vp-color-ternary-light: rgb(var(--vp-color-ternary-light-rgb));
}

body {
    min-height: 100vh;
    font-family: Inter, Arial !important;
}

.btn-fill {
    border-radius: 15px !important;
	background-color: var(--vp-color-secondary) !important;
	color: white !important;
	transition: 0.3s background-color !important;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    width: max-content;
    font-weight: 600 !important;
    font-size: 0.92em !important;

    &[type="submit"] {
        margin-left: auto;
        padding: 10px 20px;
    }    

    &:hover {
        background-color: var(--vp-color-secondary-alt) !important;
        color: white !important;
        cursor: pointer;
    }
}

.btn-outline {
    border-radius: 15px !important;
	background: inherit;
	border: 1px solid var(--vp-color-secondary) !important;
	color: var(--vp-color-secondary) !important;
	transition: 0.3s background-color !important;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    width: max-content;
    font-weight: 600 !important;
    font-size: 0.92em !important;
    &:hover {
        background-color: var(--vp-color-secondary-alt) !important;
        border: 1px solid var(--vp-color-secondary-alt) !important;
        color: white !important;
    }
}

.btn-text {
	background: inherit;
	color: var(--vp-color-secondary) !important;
    text-decoration: none;
    &:hover {
        color: var(--vp-color-secondary-alt) !important;
    }    
}

.red-checkbox {
    &input[type="checkbox"], input[type="checkbox"] {
        background: none;
        border-color: #B42318;
        &:focus, &:focus-visible {
            box-shadow: none;
        }
        &:checked::after {
            content: '✔';
            display: block;
            translate: 5%;
            font-size: 0.65em;
            background: none;
            filter: contrast(10) hue-rotate(145deg)
        }
    }
}

.secondary-checkbox {
    &input[type="checkbox"], input[type="checkbox"] {
        background: none;
        border-color: var(--vp-color-secondary);
        &:focus, &:focus-visible {
            box-shadow: none;
        }
        &:checked::after {
            content: '✔';
            display: block;
            translate: 5%;
            font-size: 0.65em;
            background: none;
            filter: contrast(10) hue-rotate(312deg)
        }
    }
}

.flash-success, .flash-warning, .flash-error {
    background-color: green;
    box-shadow: 0px 3px 11px green;
    color: white;
    font-weight: bold;
    padding: 10px 30px;
    position: absolute;
    top: 190px;
    width: 100%;
    z-index: 10;
}

.flash-success {
    background-color: green;
    box-shadow: 0px 3px 11px green;
}
.flash-warning {
    background-color: gold;
    box-shadow: 0px 3px 11px gold;
}
.flash-error {
    background-color: red;
    box-shadow: 0px 3px 11px red;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

.danger-text {
    color: #B42318 !important;
    display: flex;
    font-size: 0.75em;
    column-gap: 5px;
    margin-left: 5px;
    margin-top: 5px;
    font-weight: 600;
    span {
        font-size: medium;
        font-weight: 600;
    }
}

.required:after {
    color: var(--vp-color-secondary);
    border-radius: 50%;
    content: '*';
    display: inline-block;
    filter: opacity(75%);
    position: relative;
    right: -2px;
}

.rounded-pill.text-bg-warning {
    --bs-bg-opacity: 0.6;
    opacity: 0.9;
}

form {
    input, select {
        & + .error {
            font-size: 12px;
            color: red;
            text-align: start !important;
            font-weight: 600;
        }
    }
}

.h-separator {
    border: 0.75px solid var(--vp-color-ternary-light);
    width: 100%;
    margin: 6px 0;
    padding: 0 !important;
}